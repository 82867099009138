
<template>
  <div  class="main-layout">

    <div class="title-info">
      <img src="@/assets/common/icon_swipe_left.png" alt="" @click="$router.go(-1)">
    </div>

    <div  class="coupons-detail">

        <div class="card-body" >
          <p class="card-body-title">{{detail.title}}</p>
          <p class="card-body-date">有效期：{{detail.dateStr}}</p>

          <div class="hx-desc">
            <p>线下核销码</p>
          </div>
          <div id="qrcode" style="padding: 10px" class="qrcode" @click="toBigCode">

          </div>
          <p class="card-code">{{detail.code}}</p>






      </div>

        <p class="use">使用说明</p>

       <p class="use-desc" v-html="detail.remark"></p>

    </div>




    <van-popup  v-model:show="showBigCode" >

      <div class="bigCode" id='bigCode'>

      </div>

    </van-popup>



  </div>



</template>

<script>
import {onMounted, toRefs, getCurrentInstance, reactive, nextTick, ref,} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Qrcode from "qrcodejs2";
import {clearToast, showLoading} from "../../../utils/util";




export default {
  name: "myCouponsDetail",
  components: {

  },
  setup(props, context) {

    document.title = '卡券详情'
    const { proxy } = getCurrentInstance();
    const $store = useStore();
    const $router = useRouter();
    const data = reactive({
      detail:JSON.parse($router.currentRoute.value.query.data),


    })

    const showBigCode =  ref(false);






    // const loadData = () => {
    //
    //
    //   showLoading()
    //   proxy.$http.get("/api/cms/cs_reading/client/coupon/info/" + $router.currentRoute.value.query.id).then(res => {
    //     clearToast()
    //     data.couponCounts =  res.data.data.couponCounts
    //     data.canUseCounts =  res.data.data.canUseCounts
    //     data.couponList = res.data.data.couponList
    //
    //   })
    // }

    // loadData();

    var qrcode;
    onMounted(()=>{
      makeQrcode()
    })



    const  makeQrcode = () => {


        nextTick(()=>{

            let dom = document.getElementById('qrcode')
            if(dom){
              dom.innerText = ''
              let  value =  data.detail.code;
              qrcode = new Qrcode(dom,{
                // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
                text:  value,
                width:dom.offsetWidth - 20,
                height:dom.offsetWidth - 20,
                colorDark:'#111',//二维码颜色
                colorLight:'#fff',//二维码背景颜色
                correctLevel:Qrcode.CorrectLevel.L//容错率,L/M/H
              })
            }


          });







    }



    const toBigCode = () => {

      showBigCode.value = true;
      let value = data.detail.code;
       nextTick(()=>{
         let dom = document.getElementById('bigCode')
         if(dom){
           dom.innerText = '';
           qrcode = new Qrcode(dom,{
             // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
             text: value,
             width: dom.offsetWidth - 20,
             height: dom.offsetWidth - 20,
             colorDark:'#111',//二维码颜色
             colorLight:'#fff',//二维码背景颜色
             correctLevel:Qrcode.CorrectLevel.L//容错率,L/M/H
           })
         }

       })

    }



    return {
      showBigCode,
      ...data,
      toBigCode
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>

